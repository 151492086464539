import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import { API_URL } from "@/common/config";

const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = API_URL;
    },

    get(resource, slug = "") {
        return Vue.axios.get(`${resource}/${slug}`).catch(error => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    },
    post(resource, slug = "",postBody){
        return Vue.axios.post(`${resource}/${slug}`,{body:postBody}).catch(error => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    },
};

export default ApiService;

