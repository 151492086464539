import { render, staticRenderFns } from "./NotFound.vue?vue&type=template&id=20154996&scoped=true"
import script from "./NotFound.vue?vue&type=script&lang=js"
export * from "./NotFound.vue?vue&type=script&lang=js"
import style0 from "./NotFound.vue?vue&type=style&index=0&id=20154996&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20154996",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VContainer,VDivider,VImg,VListItem,VRow,VSpacer,VToolbar,VToolbarTitle})
