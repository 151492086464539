<template>
    <v-container justify-center >
        <div id="main_container">
            <v-toolbar color="transparent" class="d-flex-sm-column align-content-start ">
                <v-list-item one-line>
                    <v-img class="mr-3" src="@/assets/wildix-logo-2.png" max-width="150px"> </v-img>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>Manage Subscriptions</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-list-item>
            </v-toolbar>
            <v-divider></v-divider>
            <div class="ma-4">
                <h3>Email address: <small>{{email}}</small></h3>
                <h4>{{mainMsg}}</h4>
            </div>
            <v-divider></v-divider>
            <div  v-if="subsView" id="hide"  class="ml-10">
                <h4>Subscribed for:</h4>
                <div v-for="item in subs" :key="item.Name">
                    <v-switch  class="shrink mr-0 mt-0"
                            v-model="item.Active"
                            :label="item.Name"
                               @change="changeState(item.Name,item.Active)"
                    ></v-switch>
                </div>

            </div>
            <v-divider></v-divider>


        </div>

        <!---->
        <v-container>
            <v-row
                    class="ma-4"
                    align="center"
                    justify="center">
                <router-link to="/">
                    <v-img max-width="150" src="@/assets/logo.svg"></v-img>
                </router-link>
            </v-row>
        </v-container>
        <!--<div class="mt-n10" style="text-align: center; color: lightgrey">
            USA: +16144841546<br>
            UK: +443300563663<br>
            Italy: +39 0461 1715112<br>
            Germany and DACH countries: +49 89 2000 60 65<br>
            France and francophone countries: +33 1 76 74 79 83<br>
        </div>-->
    </v-container>
</template>

<script>
    import apiservice from "../common/api.service";

    export default {
        name: "Subscription",
        data: () => ({
            id:"",
            action:"",
            email:"",
            mainMsg:"",
            subs:[],
            subsView:false
        }),
        methods:{
            changeState(name,state) {
                let act="enable"
                if (state===false){
                    act="disable"
                }
                //console.log(name +" switch "+state);
                apiservice
                    .get("subscription", this.id+"/"+act+"/"+name)
                    .then()
                    .catch(error => {
                        console.log(error)
                    });
            },
            getSubscriptions(){
                let main = this
                apiservice
                    .get("subscription", this.id+"/"+this.action)
                    .then(response => {
                        if (response.data.error?.length>0){
                            main.email=response.data.error
                        }else{
                            main.email = response.data.Email
                            main.mainMsg = response.data.Msg
                            main.subs=response.data.Subs
                            if(main.subs.length>0){main.subsView=true}
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    });
            },
        },
        created(){
            this.id=this.$route.params.id
            this.action=this.$route.params.action
            if (this.action===undefined){
                this.action="getSubs"
            }
        },
        mounted() {
            this.getSubscriptions()
        }
    }
</script>

<style scoped>

</style>