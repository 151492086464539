<template>
  <v-app>
    <router-view></router-view>
    </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  methods: {

  },


}
</script>

<style>

  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0
  }

  h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px
  }

  h3 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 15px
  }

  h4 {
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 10px
  }

  h5 {
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 10px
  }


  body {
    background: #006bba
  }


  #app {
    background-image: radial-gradient(ellipse at center top, #569ede, #2477c0, #006bba);
    background-image: -webkit-radial-gradient(center top, ellipse farthest-side, #569ede, #2477c0, #006bba);
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #292C30;
}
  #main_container{
    background-color: white;
    border-radius:5px;
    max-width: 1185px;
    margin:0 auto;
  }
</style>
