import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router'
import Home from "./components/Home";
import PBX from "./components/PBX";
import Subscription from "./components/Subscription";
import NotFound from "./views/NotFound";
import ApiService from "./common/api.service";
import moment from 'moment'

Vue.prototype.moment = moment
Vue.use(VueRouter)
Vue.config.productionTip = false
const routes = [
  {
    path: '/',
    component: Home,
    meta: {title: 'Wildix Status Page'},
  },
  {
    path: '/pbx/:PBX',
    name: 'PBX',
    component:PBX,
  },
  {
    path: '/Subscription/:id/:action?',
    name: 'Subscription',
    component:Subscription,
  },
  { path:'*', component: NotFound }

  /*{
      path: '/:PBX',
      name: 'PBX',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      //component: () => import(/* webpackChunkName: "about"  '../views/About.vue')
  }*/
]
var router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
ApiService.init();
new Vue({
  vuetify,
  router:router,
  render: h => h(App)
}).$mount('#app')
