<template>
    <v-container justify-center >
        <div id="main_container">
            <v-toolbar color="transparent" class="d-flex-sm-column align-content-start ">
                <v-list-item one-line>
                    <v-img class="mr-3" src="@/assets/wildix-logo-2.png" max-width="150px"> </v-img>
                    <v-spacer></v-spacer>
                    <!--<v-toolbar-title>Cloud PBX <b>{{pbxName}}</b> Status</v-toolbar-title>-->
                    <v-toolbar-title>Page not found</v-toolbar-title>
                    <v-spacer></v-spacer>

                </v-list-item>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card>
                <v-container>
                    <v-row  justify="center" align="center"> <div class="not-found-title">WHOOPS!</div></v-row>
                    <v-row  justify="center" align="center"> <div class="not-found-subtitle">404</div></v-row>
                </v-container>
            </v-card>
        </div>

        <v-container>
            <v-row
                    class="ma-4"
                    align="center"
                    justify="center">
                <router-link to="/">
                    <v-img max-width="150" src="@/assets/logo.svg"></v-img>
                </router-link>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    export default {
        name: "NotFound.vue"
    }
</script>
<style scoped>
    .not-found-title {
        font-size:28px;
        display:block;
        margin-bottom:20px;
        font-weight:700;
        color: #777777;
    }
    .not-found-subtitle {
        font-size:220px;
        line-height:100%;
        display:block;
        font-weight:300;
        padding-bottom:20px;
        margin-bottom:20px;
        color:#003366;
    }
</style>
