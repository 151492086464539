<template>
    <v-content>
        <header class="page-wrapper__header">
            <div class="logo">
                <a class="logo__link" target="_blank" href="https://www.wildix.com/" >
                    <svg class="logo__image">
                        <use xlink:href="@/assets/logo.svg#logo-wildix"></use>
                    </svg>
                </a>
            </div>

            <div class="welcome-block">
                <h2 class="welcome-block__title">Welcome</h2>
                <h4 class="welcome-block__subtitle">to Wildix Cloud PBX status page</h4>
            </div>
        </header>


        <v-card
                align-center
                class="mx-auto mt-4"
                max-width="400"
                outlined raised
                color="white"
        >

            <v-text-field
                    class="ma-6"
                    label="Your PBX name"
                    outlined
                    hide-details="auto"
                    suffix=".wildixin.com"
                    v-on:keyup.enter="submit"
                    v-model="targetPBX"
            >

                <template v-slot:append>
                    <v-fade-transition leave-absolute>
                        <v-progress-circular
                                v-if="loading"
                                size="24"
                                color="info"
                                indeterminate
                        ></v-progress-circular>
                        <v-btn class="mt-n2" icon right v-on:click="submit">
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </v-fade-transition>
                </template>
            </v-text-field>
        </v-card>

        <div class="text-center">
            <v-dialog
                    v-model="dialog"
                    width="500"
            >
                <v-card>
                    <v-card-title
                            class="headline grey lighten-2"
                            primary-title
                    >
                        PBX not found
                    </v-card-title>

                    <v-card-text>

                        Please check if entered pbx name is correct
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="primary"
                                text
                                @click="dialog = false"
                        >
                            GOT IT!
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        </v-content>

</template>

<script>

import apiservice from "../common/api.service";

export default {
  name: 'Home',
    data: () => ({
        targetPBX:"",
        loading:false,
        dialog: false,
    }),
    created() {
        document.title="Wildix PBX Status page"
    },
    methods:{
      submit(){
          this.loading = true
          var me = this
          apiservice
              .get("pbx", this.targetPBX)
              .then(() => {
                  this.$router.push("/pbx/"+this.targetPBX)
              })
              .catch(function (e) {
                  console.log(e)
                  me.dialog=true
              });
          this.loading =false
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .welcome-block {
        position: absolute;
        top: 190px;
        right: 0;
        left: 0
    }

    .welcome-block .welcome-block__title,
    .welcome-block .welcome-block__subtitle {
        text-align: center;
        color: #fff
    }

    .logo {
        margin: 0 auto;

        text-align: center;
        position: relative;
        top: 0;
        left: 0;
        right: 0
    }

    .logo .logo__link {
        display: inline-block
    }

    .logo .logo__image {
        width: 256px;
        height: 256px
    }


</style>
